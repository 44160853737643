import React from "react";
import styles from "./index.module.scss";

const Roadmap = () => {
  return (
    <div id="roadmap" className={styles["roadmap-container"]}>
      <div className={styles["row"]}>
        <div className={styles["roadmap-title"]}>Roadmap</div>

        <div className={styles["roadmap-wrapper"]}>
          <div className={styles["roadmap"]}>
            <img
              className={styles["banana-opened"]}
              alt={"Banana ready to eat"}
              src={"images/banana-opened.svg"}
            />
            <div className={styles["milestone-title"]}>Banana 1</div>
            <div className={styles["milestone-description"]}>
              $SAPE (Initial Liquidity Offering) on a launchpad. ,Website
              launch, Marketing push to spread awareness, 5000 telegram members,
              Meme development, Safe Ape is Born!
            </div>
          </div>
          <div className={styles["roadmap"]}>
            <img
              className={styles["banana-closed"]}
              alt={"Banana not so ready to eat"}
              src={"images/banana-closed.svg"}
            />
            <div className={styles["milestone-title"]}>Banana 2</div>
            <div className={styles["milestone-description"]}>
              Listing on coin gecko, Influencer marketing push, Listing on coin
              market cap, 25,000 telegram members, 25,000 holders, Third party
              audit, Voluntary donation for community + charity partnership.
            </div>
          </div>
          <div className={styles["roadmap"]}>
            <img
              className={styles["banana-closed"]}
              alt={"Banana not so ready to eat"}
              src={"images/banana-closed.svg"}
            />
            <div className={styles["milestone-title"]}>Banana 3</div>
            <div className={styles["milestone-description"]}>
              100,000 telegram members, 100,000 holders, Airdrop, Large
              influencer marketing push, More memes from community, Safe Ape
              prepaid Credit Card.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
