import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Konami from "react-konami-code";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Header = () => {
  const { t } = useTranslation();

  const [bananaDetails, setBananaDetails] = useState({
    picture: "images/banana-closed.svg",
    class: "banana-closed",
  });
  const [apeVaderImage, setApeVaderImage] = useState("images/ape.svg");
  const [moonImage, setMoonImage] = useState("images/moon.svg");
  const [saberOn, setSaberOn] = useState(false);
  const { uiStore } = useStores();
  const { setApeVaderBucketImage } = uiStore;

  const easterEgg = () => {
    setApeVaderImage("images/ape-vader.svg");
    setApeVaderBucketImage("images/ape-vader-bucket.svg");
    setMoonImage("images/death-star.svg");
    setSaberOn(true);
  };

  return (
    <>
      <Konami action={easterEgg} />
      <div id="header" className={styles["header-container"]}>
        <div className={styles["row"]}>
          <div className={styles["column"]}>
            <div className={styles["header-welcome-wrapper"]}>
              <img
                className={styles["logo"]}
                src={"images/logo.png"}
                alt="SafeApe logo"
              />
              <div className={styles["welcome-message"]}>
                {t("header.title")}
              </div>
              <div className={styles["welcome-description"]}>
                {t("header.description")}
              </div>
              <div className={styles["token-contract"]}>
                {t("header.token_contract")}
                <span className={styles["token-address"]}>
                  0xa96f3414334f5a0a529ff5d9d8ea95f42147b8c9
                </span>
              </div>
              <div
                className={styles["ape-in-button"]}
                onMouseOver={() => {
                  setBananaDetails({
                    picture: "images/banana-opened.svg",
                    class: "banana-opened",
                  });
                }}
                onMouseOut={() => {
                  setBananaDetails({
                    picture: "images/banana-closed.svg",
                    class: "banana-closed",
                  });
                }}
              >
                <img
                  className={styles[bananaDetails.class]}
                  alt="Banana button"
                  src={bananaDetails.picture}
                />
                {t("header.button")}
                <span className={styles["button-slippage"]}>
                  {t("header.button_description")}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["column"]}>
            <div className={styles["ape-wrapper"]}>
              <img
                className={styles["ape"]}
                src={apeVaderImage}
                alt="SafeApe himself"
              />
              <img className={styles["moon"]} src={moonImage} alt="Moon" />
              <img
                className={styles["rocket"]}
                src={"images/rocket.svg"}
                alt="Rocket"
              />
              {Array.from(Array(3).keys()).map((i) =>
                saberOn && i === 0 ? (
                  <img
                    key={i}
                    className={styles[`saber`]}
                    src={"images/ape-saber.svg"}
                    alt={`SafeApe's ${i}. banana`}
                  />
                ) : (
                  <img
                    key={i}
                    className={styles[`banana-${i}`]}
                    src={"images/banana.svg"}
                    alt={`SafeApe's ${i}. banana`}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
