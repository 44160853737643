import React from "react";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Tokenomics = () => {
  const { uiStore } = useStores();
  const { apeVaderBucketImage } = uiStore;

  return (
    <div id="tokenomics" className={styles["tokenomics-container"]}>
      <div className={styles["row"]}>
        <div className={styles["tokenomics-title"]}>Tokenomics</div>

        <div className={styles["tokenomics-column-1"]}>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
        </div>
        <div className={styles["tokenomics-column-2"]}>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
          <div className={styles["tokenomic"]}>
            Initial Supply: 600,000,000,000 SAPE
          </div>
        </div>
        <img
          src={apeVaderBucketImage}
          alt={"Ape Vader with Bananas"}
          className={styles["ape-with-bananas"]}
        />
        <div className={styles["matic-explorer"]}>
          {"Check Matic Explorer >"}
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
