import React from "react";
import styles from "./index.module.scss";

const Spinner = () => {
  return (
    <div className={styles["spinner-container"]}>
      <img
        alt={"Spinner"}
        className={styles["spinner-image"]}
        src={"images/0.png"}
      />
    </div>
  );
};

export default Spinner;
