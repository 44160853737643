import React, { useEffect } from "react";
import Particles from "react-particles";
import Header from "../../components/header";
import Roadmap from "../../components/roadmap";
import Stickers from "../../components/stickers";
import Tokenomics from "../../components/tokenomics";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Home = () => {
  const { uiStore } = useStores();
  const { setHomeMounted } = uiStore;

  useEffect(() => {
    setHomeMounted(true);
    return () => {
      setHomeMounted(false);
    };
  });

  return (
    <>
      <Particles
        className={commonStyles["particles"]}
        params={{
          particles: {
            number: {
              value: 20,
              density: {
                enable: false,
                value_area: 10000,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "image",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "images/star.svg",
                width: 1,
                height: 2,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: true,
                rotateX: 600,
                rotateY: 600,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3,
              },
              repulse: {
                distance: 400,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <div className={commonStyles["header-background"]}>
        <div className={commonStyles["container"]}>
          <Header />
          <Stickers />
          <Tokenomics />
          <Roadmap />
        </div>
      </div>
    </>
  );
};

export default Home;
