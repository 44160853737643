import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Stickers = () => {
  const { t } = useTranslation();
  const [codeOne, setCodeOne] = useState(false);
  const [codeTwo, setCodeTwo] = useState(false);
  const [codeThree, setCodeThree] = useState(false);
  const [codeFour, setCodeFour] = useState(false);

  return (
    <>
      <div className={styles["sticker-container"]}>
        <div className={styles["row"]}>
          <div className={styles["sticker"]}>
            <div className={styles["sticker-1"]}>
              <img
                className={styles["sticker-image"]}
                alt={"Holders"}
                src={"images/ape-cap.svg"}
                onClick={() => setCodeOne(!codeOne)}
              />
              {!codeOne ? (
                <div className={styles["sticker-text"]}>Holders</div>
              ) : (
                <div className={styles["sticker-text"]}>UP UP</div>
              )}
            </div>
          </div>
          <div className={styles["sticker"]}>
            <div className={styles["sticker-2"]}>
              <img
                className={styles["sticker-image"]}
                alt={"Holders"}
                src={"images/ape-hold.svg"}
              />
              <div className={styles["sticker-text"]}>Holders</div>
            </div>
          </div>
          <div className={styles["sticker"]}>
            <div className={styles["sticker-3"]}>
              <img
                className={styles["sticker-image"]}
                alt={"Transactions"}
                src={"images/ape-transactions.svg"}
                onClick={() => setCodeTwo(!codeTwo)}
              />
              {!codeTwo ? (
                <div className={styles["sticker-text"]}>Transactions</div>
              ) : (
                <div className={styles["sticker-text"]}>DOWN DOWN</div>
              )}
            </div>
          </div>
          <div className={styles["sticker"]}>
            <div className={styles["sticker-4"]}>
              <img
                className={styles["sticker-image-burn"]}
                alt={"Burned Tokens"}
                src={"images/ape-burn.svg"}
              />
              <div className={styles["sticker-text"]}>Burned Tokens</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["feature-container"]}>
        <div className={styles["row"]}>
          <div className={styles["feature"]}>
            <div className={styles["feature-1"]}>
              <div className={styles["feature-text"]}>
                Auto burn
                <div className={styles["feature-line"]} />
                <div className={styles["feature-text-description"]}>
                  The dead wallet holds 45% of the supply thus 45% of the 5% tax
                  will be redistributed to the dead wallet after every single
                  transaction. Thus each on chain transaction has a deflationary
                  effect on the supply.
                </div>
              </div>
            </div>
          </div>
          <div className={styles["feature"]}>
            <div className={styles["feature-2"]}>
              <div
                className={styles["feature-text"]}
                onClick={() => setCodeThree(!codeThree)}
              >
                Feature number One very bullish
                <div className={styles["feature-line"]} />
                {!codeThree ? (
                  <div className={styles["feature-text-description"]}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris sed justo auctor.
                  </div>
                ) : (
                  <div className={styles["feature-text-description"]}>
                    LEFT RIGHT LEFT RIGHT
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles["feature"]}>
            <div className={styles["feature-3"]}>
              <div className={styles["feature-text"]}>
                5% transaction fee
                <div className={styles["feature-line"]} />
                <div className={styles["feature-text-description"]}>
                  A 5% fee is applied to every transaction. The fees generated
                  will be redistributed to $SAPE holders. That means you can
                  earn more Safe Ape tokens simply by holding them in your
                  wallet.
                </div>
              </div>
            </div>
          </div>
          <div className={styles["feature"]}>
            <div className={styles["feature-4"]}>
              <div
                className={styles["feature-text"]}
                onClick={() => setCodeFour(!codeFour)}
              >
                Charity wallet
                <div className={styles["feature-line"]} />
                {!codeFour ? (
                  <div className={styles["feature-text-description"]}>
                    We set a designated wallet that receives redistributions
                    that will be used to support Ape Action Africa
                    (https://www.apeactionafrica.orgApe Action Africa
                    (https://www.apeactionafrica.org/)) a wildlife conservation
                    organisation working in Cameroon, Central Africa, rescuing
                    and rehabilitating primates. We plan to expand our support
                    to more charities as the Safe Ape community grows
                  </div>
                ) : (
                  <div className={styles["feature-text-description"]}>B A</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stickers;
