const ScrollLinkEnum = Object.freeze({
    ABOUT: "About",
    TOKENOMICS: "Tokenomics",
    ROADMAP: "Roadmap",
    TRADE: "Trade",
    SOCIALS: "SOCIALS",
    CONTRACT: "Contract",
    NONE: "NONE"
})

export default ScrollLinkEnum;