import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NavigationBar = () => {
  const { t } = useTranslation();
  const { uiStore } = useStores();
  const { hamburgerMenuOpen, switchHamburgerMenu, scrollLink, setScrollLink } =
    uiStore;
  const [navbarBackground, setNavbarBackground] = useState(
    "navbar-without-background"
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setNavbarBackground("navbar-with-background");
      } else {
        setNavbarBackground("navbar-without-background");
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={styles["navbar-container"]}>
        <div className={styles[navbarBackground]}>
          <div className={styles["navbar"]}>
            <div className={styles["links-container"]}>
              <div className={styles["links"]}>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.ABOUT)}
                  exact={true}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.ABOUT
                      ? styles["active-link"]
                      : null
                  }
                  to="/"
                >
                  {t("navbar.about")}
                </NavLink>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.TOKENOMICS)}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.TOKENOMICS
                      ? styles["active-link"]
                      : null
                  }
                  to="/#tokenomics"
                >
                  {t("navbar.tokenomics")}
                </NavLink>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.ROADMAP)}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.ROADMAP
                      ? styles["active-link"]
                      : null
                  }
                  to="/#roadmap"
                >
                  {t("navbar.roadmap")}
                </NavLink>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.TRADE)}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.TRADE
                      ? styles["active-link"]
                      : null
                  }
                  to="/#faq"
                >
                  {t("navbar.trade")}
                </NavLink>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.SOCIALS)}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.SOCIALS
                      ? styles["active-link"]
                      : null
                  }
                  to="/#socials"
                >
                  {t("navbar.socials")}
                </NavLink>
                <NavLink
                  onClick={() => setScrollLink(ScrollLinkEnum.CONTRACT)}
                  activeClassName={
                    scrollLink === ScrollLinkEnum.CONTRACT
                      ? styles["active-link"]
                      : null
                  }
                  to="/#faq"
                >
                  {t("navbar.contract")}
                </NavLink>
              </div>
              <div className={styles["theme-wrapper"]}>
                <div
                  className={styles["hamburger-menu"]}
                  onClick={() => {
                    switchHamburgerMenu(!hamburgerMenuOpen);
                  }}
                >
                  {t("navbar.menu")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hamburgerMenuOpen ? (
        <div className={styles["hamburger-menu-dropdown"]}>
          <div className={styles["responsive-links-container"]}>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.ABOUT);
              }}
              exact={true}
              activeClassName={
                scrollLink === ScrollLinkEnum.ABOUT
                  ? styles["active-link"]
                  : null
              }
              to="/"
            >
              {t("navbar.about")}
            </NavLink>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.TOKENOMICS);
              }}
              activeClassName={
                scrollLink === ScrollLinkEnum.TOKENOMICS
                  ? styles["active-link"]
                  : null
              }
              to="/#tokenomics"
            >
              {t("navbar.tokenomics")}
            </NavLink>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.ROADMAP);
              }}
              activeClassName={
                scrollLink === ScrollLinkEnum.ROADMAP
                  ? styles["active-link"]
                  : null
              }
              to="/#roadmap"
            >
              {t("navbar.roadmap")}
            </NavLink>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.TRADE);
              }}
              activeClassName={
                scrollLink === ScrollLinkEnum.TRADE
                  ? styles["active-link"]
                  : null
              }
              to="/#faq"
            >
              {t("navbar.trade")}
            </NavLink>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.SOCIALS);
              }}
              activeClassName={
                scrollLink === ScrollLinkEnum.SOCIALS
                  ? styles["active-link"]
                  : null
              }
              to="/#socials"
            >
              {t("navbar.socials")}
            </NavLink>
            <NavLink
              onClick={() => {
                switchHamburgerMenu(!hamburgerMenuOpen);
                setScrollLink(ScrollLinkEnum.CONTRACT);
              }}
              activeClassName={
                scrollLink === ScrollLinkEnum.CONTRACT
                  ? styles["active-link"]
                  : null
              }
              to="/#faq"
            >
              {t("navbar.contract")}
            </NavLink>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavigationBar;
