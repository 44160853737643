import React from "react";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Backdrop = () => {
  const { uiStore } = useStores();
  const { hamburgerMenuOpen, switchHamburgerMenu } = uiStore;

  return (
    <>
      {hamburgerMenuOpen && (
        <div
          onClick={() => switchHamburgerMenu(false)}
          className={styles["backdrop-container"]}
        />
      )}
    </>
  );
};

export default Backdrop;
