import { makeObservable } from "mobx";
import React from "react";
import UiStore from "./UiStore";

export class RootStore {
  constructor() {
    this.uiStore = new UiStore();
    makeObservable(this, {});
  }
}

export const StoreContext = React.createContext();

export const StoreProvider = ({ children, store }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

export const useStores = () => React.useContext(StoreContext);
// eslint-disable-next-line
export default { RootStore, StoreContext, StoreProvider };
