export const TRANSLATIONS_EN = {
  navbar: {
    about: "About",
    tokenomics: "Tokenomics",
    roadmap: "Roadmap",
    trade: "Trade",
    socials: "Socials",
    contract: "Contract",
    menu: "Menu",
  },
  header: {
    title: "Ape and save Apes with the Safe Ape token",
    description:
      "Despite the name Safe Ape is actually a community driven token built on the Binance Smart Chain with the purpose to spread awareness of the primates endangerment. Originally created as a meme joke with a fixed 600 Billion supply (Fun fact: the International Union for Conservation of Nature recognizes well over 600 primate species and subspecies… and counting). The deflationary mechanics decreases the total supply with each transaction.  Why print when you can burn?",
    token_contract: "Contract:",
    button: "Ape in",
    button_description: "5% slippage",
  },
  stickers: {
    market_cap: "Market Cap",
    holders: "Holders",
    transactions: "Transactions",
    burned_tokens: "Burned Tokens",
  },
  features: {
    items: [
      {
        title: "Auto burn",
        description:
          "The dead wallet holds 45% of the supply thus 45% of the 5% tax will be redistributed to the dead wallet after every single transaction. Thus each on chain transaction has a deflationary effect on the supply.",
      },
      {
        title: "Feature number One very bullish",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed justo auctor.",
      },
      {
        title: "5% transaction fee",
        description:
          "A 5% fee is applied to every transaction. The fees generated will be redistributed to $SAPE holders. That means you can earn more Safe Ape tokens simply by holding them in your wallet.",
      },
      {
        title: "Charity wallet",
        description:
          "We set a designated wallet that receives redistributions that will be used to support Ape Action Africa (https://www.apeactionafrica.orgApe Action Africa (https://www.apeactionafrica.org/)) a wildlife conservation organisation working in Cameroon, Central Africa, rescuing and rehabilitating primates. We plan to expand our support to more charities as the Safe Ape community grows",
      },
    ],
  },
  tokenomics: {
    items: [
      { title: "Initial Supply: 600,000,000,000 SAPE" },
      { title: "Initial Supply: 600,000,000,000 SAPE" },
      { title: "Initial Supply: 600,000,000,000 SAPE" },
      { title: "Initial Supply: 600,000,000,000 SAPE" },
      { title: "Initial Supply: 600,000,000,000 SAPE" },
      { title: "Initial Supply: 600,000,000,000 SAPE" },
    ],
    bsc_explorer: "Check BSC Explorer",
  },
  roadmap: {
    items: [
      {
        title: "Banana 1",
        description:
          "$SAPE (Initial Liquidity Offering) on a launchpad.   ,Website launch, Marketing push to spread awareness, 5000 telegram members, Meme development, Safe Ape is Born! ",
      },
      {
        title: "Banana 2",
        description:
          " Listing on coin gecko, Influencer marketing push, Listing on coin market cap, 25,000 telegram members, 25,000 holders, Third party audit, Voluntary donation for community + charity partnership.",
      },
      {
        title: "Banana 3",
        description:
          "100,000 telegram members, 100,000 holders, Airdrop, Large influencer marketing push, More memes from community, Safe Ape prepaid Credit Card.",
      },
    ],
  },
};
