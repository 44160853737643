import React, { useEffect } from "react";
import NotificationTypeEnum from "../../enums/notification-type-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Notification = () => {
  const { uiStore } = useStores();
  const { notificationBar, setNotificationBarVisibility } = uiStore;
  const { isVisible, type, message } = notificationBar;

  const onClose = () => {
    setNotificationBarVisibility(false);
  };

  useEffect(() => {
    if (isVisible) {
      setInterval(() => {
        setNotificationBarVisibility(false);
      }, 7000);
    }
  }, [isVisible, setNotificationBarVisibility]);

  return (
    <>
      {isVisible &&
        (type === NotificationTypeEnum.SUCCESS ? (
          <div className={styles["notification-container-success"]}>
            <div
              className={styles["notification-container-x"]}
              onClick={() => onClose()}
            >
              ✖
            </div>
            <div className={styles["notification-title"]}>Success</div>
            <div className={styles["notification-description"]}>{message}</div>
          </div>
        ) : (
          <div className={styles["notification-container-error"]}>
            <div
              className={styles["notification-container-x"]}
              onClick={() => onClose()}
            >
              ✖
            </div>
            <div className={styles["notification-title"]}>Error</div>
            <div className={styles["notification-description"]}>{message}</div>
          </div>
        ))}
    </>
  );
};

export default Notification;
